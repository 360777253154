import { ref } from "vue";
import { useStorage } from "@vueuse/core";
import { isAllowLocalStorage } from "~/helpers/permissions";

const localStorageKey = "hungryhub_selected_city_id";
const defaultSelectedCityId = "1"; // Bangkok

const selectedCityId = isAllowLocalStorage()
  ? useStorage(localStorageKey, defaultSelectedCityId)
  : ref(defaultSelectedCityId);

export { selectedCityId };
