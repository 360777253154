import { useWindow } from "#imports";

export function requestIdle(callback: () => any) {
  if ("requestIdleCallback" in window === false) {
    callback();
    return;
  }
  useWindow?.requestIdleCallback(() => {
    callback();
  });
}
