import { onMounted, ref } from "vue";
import { requestIdle } from "~/helpers/requestIdle";

function useRequestIdle() {
  const isIdle = ref(false);

  onMounted(() => {
    requestIdle(() => {
      isIdle.value = true;
    });
  });

  return {
    isIdle,
  };
}

export { useRequestIdle };
